import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit, hideIfEmpty } from '@/utils/list-generators';
import {
  genMenuItemDefault,
  genMenuItemShowHistory
} from '@/utils/list-generators/menu';
import router from '@/router';

import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { objectItemTypes } from '@/modules/objects/ui/object-card/general/item-helpers';
import { objectPropertyService } from '@/modules/common/api';
import { typeCast } from '@/provider/utils';

export function useMonitoringItemMenu() {
  const popup = usePopup();
  const { getNextRoute } = useHistoryStack();

  return {
    genMenu: (monitoringItem, objectId) => {
      const {
        id,
        statePropertyId,
        alertPropertyId,
        commonAlertCount,
        propertyValueObject
      } = monitoringItem;
      return [
        genMenuItemEdit(
          () => {
            popup.open({
              component: () => import('./MonitoringItemEdit.vue'),
              props: {
                monitoringItemId: id,
                objectId
              }
            });
          },
          {
            title: 'Source'
          }
        ),
        genMenuItemShowHistory(async () => {
          await router.push(
            getNextRoute({
              component: componentAliases.hec,
              props: {
                bench: 30,
                ids: [statePropertyId, alertPropertyId],
                monitoringItem: {
                  name: monitoringItem.infoName,
                  id,
                  type: 'monitoringItem'
                }
              }
            })
          );
        }),
        ...hideIfEmpty(commonAlertCount, [
          genMenuItemDefault(
            {
              id: 'alarms',
              icon: '$alert',
              title: 'Alarms'
            },
            {
              click: () => {
                popup.open({
                  component: () => import('./ObjectItemAlarmsEdit.vue'),
                  props: {
                    objectItemId: id,
                    objectId,
                    itemType: objectItemTypes.monitoringItem
                  }
                });
              }
            }
          )
        ]),
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/EditProperty.vue'),
            props: {
              title: 'Edit Value',
              currentItem: propertyValueObject,
              onConfirm: value =>
                objectPropertyService.update(propertyValueObject.id, {
                  value: typeCast(propertyValueObject.type.is, value)
                })
            }
          });
        })
      ];
    }
  };
}
