import {
  flattenObject,
  getObjectPropertyByKey,
  patchObjectsByObjectId,
  SUB_EVENT_TYPES
} from '@/provider/utils';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import itemsSchema from '../api/object-management-items-list.graphql';
import { abcSort } from '@/compositions/sortBy';
import { promisifyQuery } from '@/utils';

export function useManagementItems({ objectId }) {
  const { subscribeToMore, result, load, onResult, onError } = useLazyQuery(
    itemsSchema.load,
    {
      objectId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const list = useResult(result, [], data =>
    data.objectsToObjects
      .map(i => {
        const property = getObjectPropertyByKey(i.object2, 'stateControlRpc');
        return {
          linked: !!property.value?.sourceId,
          stateControlRpcId: property.id,
          ...flattenObject(i.object2)
        };
      })
      .sort((a, b) => abcSort(a.name, b.name))
  );

  subscribeToMore({
    document: itemsSchema.listenManagementItemsList,
    variables: {
      objectId
    },
    updateQuery: (previousResult, { subscriptionData }) => {
      console.log('object management items list subscription');
      const relatedNode = subscriptionData.data?.Objects?.relatedNode;
      const eventType = subscriptionData.data?.Objects?.event;
      if (eventType !== SUB_EVENT_TYPES.insert) return;
      if (!relatedNode) return;
      switch (relatedNode.__typename) {
        case 'ObjectsToObject': {
          return {
            objectsToObjects: [...previousResult.objectsToObjects, relatedNode]
          };
        }
        case 'ObjectProperty': {
          const { objectId } = relatedNode;
          const patchedObjects = patchObjectsByObjectId(
            objectId,
            'objectProperties',
            relatedNode,
            previousResult.objects
          );

          if (patchedObjects) {
            return {
              objects: patchedObjects
            };
          }
        }
      }
    }
  });

  return {
    load: promisifyQuery(load, onResult, onError),
    list
  };
}
